<template>
  <!-- Modal dialog: Logout -->
  <v-dialog 
    transition="dialog-top-transition"
    v-model="dialog" 
    width="500"
    @keydown.esc="no" 
    persistent>
    <v-card-title class="dialogTitleClass bg-dialog-error"><v-icon class="mr-2 icon-error">mdi-login-variant</v-icon>{{ title }}</v-card-title>

    <v-card-text v-show="!!message" class="dialogTextClass px-6" v-html="message"></v-card-text>

    <v-card-actions>
      <v-container>
        <v-row>
          <v-col>
            <v-btn v-if="!options.noconfirm" class="btn-red" @click.native="yes">SI</v-btn>
          </v-col>
          <v-col>
            <v-btn v-if="!options.noconfirm" class="btn-gray" @click.native="no">NO</v-btn>              
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-dialog>
</template>

<script>
  export default {
    name: "dialogRemove",
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        data: undefined, 
        section: undefined, 
        table: undefined,
        variable: undefined,
        options: {
          noconfirm: false,
        },
      };
    },
    methods: {
      open(title, message, data, table, variable, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.data = data;
        this.table = table;
        this.variable = variable;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      yes() {
        this.resolve(false);
        this.dialog = false;
        // Remove local
        for (const [i, table] of this.table.data.entries()) {
          if(table.id == this.data.id){
            this.table.data.splice(i, 1);
          }
        }
        // Remove DB
        this.$emit('resRemove', {data: this.data, table: this.table, variable: this.variable});
      },
      no() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  };
</script>